import { useTranslation } from '@loveholidays/phrasebook';
import React, { memo } from 'react';

import { Suffix, SuffixType } from './PriceTypes';
import { StaticPrice, StaticPriceProps } from './StaticPrice';
import { DataStoreName, usePassengerPrice } from './usePassengerPrice';
import { usePriceQuoteUnit } from '@Core/prices/usePriceQuoteUnit';

const translations: Record<Suffix, Record<SuffixType, string>> = {
  PER_PERSON: {
    short: 'priceQuoteUnit.perPersonShort',
    long: 'priceQuoteUnit.perPerson',
  },
  TOTAL: {
    short: 'priceQuoteUnit.totalShort',
    long: 'priceQuoteUnit.total',
  },
};

export function getPriceQuoteUnitSuffix(unit: Suffix, type: SuffixType) {
  return translations[unit][type];
}

interface PassengerPriceProps extends Omit<StaticPriceProps, 'suffix'> {
  /**
   * Adds a suffix to the price. EG: £100 pp or £100 per person.
   * If you don't supply a suffixType it will not show a suffix. EG: £100
   */
  suffixType?: SuffixType;

  store?: DataStoreName;
}

/**
 * Renders a price with the suffix based on the user preferences and the number of chargeable passengers in the store.
 * Price will update when the user toggles between per person and total on the site.
 */
export const PassengerPrice: React.FC<PassengerPriceProps> = memo(
  ({ value, suffixType, store = 'SEARCH_SELECTION', ...rest }) => {
    const { t } = useTranslation();
    const { priceQuoteUnit } = usePriceQuoteUnit();
    const modifiedValue = usePassengerPrice(value, store)!;

    const suffix = suffixType ? t(getPriceQuoteUnitSuffix(priceQuoteUnit, suffixType)) : undefined;

    return (
      <StaticPrice
        value={modifiedValue}
        suffix={suffix}
        {...rest}
      />
    );
  },
);
