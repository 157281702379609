import { RoomConfigurationInput } from '@AuroraTypes';
import { getNumberOfChargeablePassengers } from '@Core/prices/getNumberOfPassengers';
import { usePriceQuoteUnit } from '@Core/prices/usePriceQuoteUnit';
import { useOrderStore } from '@Stores/checkout/CheckoutStoreContext';
import { useSearchSelectionStore } from '@Stores/StoreContext';

export type DataStoreName = 'ORDER' | 'SEARCH_SELECTION';

export const usePassengerPrice = (
  value?: number,
  store: DataStoreName = 'SEARCH_SELECTION',
): number | null => {
  const { priceQuoteUnit } = usePriceQuoteUnit();

  if (!value) return null;

  let roomConfiguration: RoomConfigurationInput[] = [];
  if (store === 'ORDER') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const hotelProduct = useOrderStore((state) => state.getHotel());

    if (hotelProduct) {
      roomConfiguration = hotelProduct.rooms.map(({ partyConfiguration }) => partyConfiguration);
    }
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    roomConfiguration = useSearchSelectionStore(({ rooms }) => rooms);
  }

  if (priceQuoteUnit === 'PER_PERSON') {
    const persons = getNumberOfChargeablePassengers(roomConfiguration);

    return value / (persons || 1);
  }

  return value;
};
